import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"

      version="1.1"
      viewBox="0 0 150 141.454"
    >
      <g
        fill="#fff"
        fillOpacity="1"
        stroke="none"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="3.3"
        strokeOpacity="1"
        strokeWidth="1"
        paintOrder="stroke fill markers"
        transform="translate(-33.275 -63.401)"
      >
        <rect
          width="150"
          height="15"
          x="33.275"
          y="63.401"
          rx="8"
          ry="8"
        ></rect>
        <rect
          width="150"
          height="15"
          x="33.275"
          y="189.855"
          rx="8"
          ry="8"
        ></rect>
        <rect
          width="120"
          height="15"
          x="48.275"
          y="95.015"
          rx="8"
          ry="8"
        ></rect>
        <rect
          width="120"
          height="15"
          x="48.275"
          y="158.242"
          rx="8"
          ry="8"
        ></rect>
        <rect
          width="90"
          height="15"
          x="63.275"
          y="126.628"
          rx="8"
          ry="8"
        ></rect>
      </g>
    </svg>
  );
}

export default Icon;
