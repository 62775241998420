import Reac, {useEffect, useState,useRef  } from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";
import { Box } from '@mui/material';


export default function MultiLedColourPicker({numberOfLEDS, onStateChange,frameCurrentSettings}) 
{


    function handleUpdateSingleLED(index, value)
    {
        let array = [...colorarray];
        array[index]=value;
        setColorArray(array);
        onStateChange(array);
    }


    const getDefaultLEDArray = ()=>
    {

        let leds = [];

        if(frameCurrentSettings !== undefined && frameCurrentSettings.length === numberOfLEDS )
        {

            for(let i =0; i < numberOfLEDS; i++)
            {
                leds[i]=frameCurrentSettings[i];
            }

        }
        else
        {
            if(numberOfLEDS > 0 )
            {
                for(let i =0; i < numberOfLEDS; i++)
                {
                    leds[i]="#000000";
                }
            }
        }
        return leds;
    };




    const allLEDS = () => 
    {
        let content1 = [];

        if(numberOfLEDS > 0)
        {

            for(let i = 0; i<numberOfLEDS; i++ )
            {

                content1.push( 
                    <div style={{padding: "2px 2px 10px 2px", backgroundImage: "linear-gradient(360deg, black 24%, #1a1a1a)", borderRadius:"10px", margin:"10px", boxShadow:"6px 7px 10px -4px rgb(79 79 79)"}}>
                        <HexColorPicker className='small'  color={colorarray[i]} onChange={e=>{handleUpdateSingleLED(i,e)}} />
                        <HexColorInput style={{boxShadow:`0px 0px 10px 6px ${colorarray[i]}`, marginTop:"20px", marginBottom:"10px",color:`${colorarray[i]}`, background:`${colorarray[i]}`, border:"0"}}   className='colourPicker' color={colorarray[i]} onChange={e=>{handleUpdateSingleLED(i,e)}} />
                    </div>
                );

            }

        }
        return content1;

    };

    const [colorarray, setColorArray] = useState(getDefaultLEDArray());


return (
    <div className="pickAColourSingle" style={{display:"flex", flexWrap:"wrap", alignItems:"center", justifyContent:"center", paddingBottom:50}}>
        {allLEDS()}
    </div>
);

};