import Reac, { useState, useEffect, useRef } from 'react';
import { Routes, Route } from "react-router-dom"

import logo from './logo.svg';
import './App.css';
import BluetoothTerminal from './Helpers/BluetoothTerminal';
import VariantButtonGroup from './ModeSelectButtons';
import { Box, Button } from '@mui/material';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import ResponsiveAppBar from './ResponsiveAppBar';
import SimpleBottomNavigation from './BottomNavBar';
import ColorPickerPage from './ColourPickerPage';
import AnimationMakerPage from './AnimationMakerPage';
import ReconnectingModal from './ReconnectingComponant' 
import ConnectionFailedComponant from './ConnectionFailedComponant';
import SettingsPage from './SettingsPage'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {hardwareManager} from './Helpers/HardwareController'
import { deviceStorageManager } from './Helpers/DevicesManager.ts';
const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


deviceStorageManager.LoadDevicesFromStorage();

function App() {



  const connectionFailRef = useRef();
  connectionFailRef.current = function() {};
  const [reconnecting,setReconnecting] = useState(false);

  const [espsettings, setEspSettings] = useState(hardwareManager.GetSettings());

  const reconnectFailed = function() {
    
      setReconnecting(false);
      connectionFailRef.current.toggleShow();
    }

  
  const handleSettingsUpdated = (event) => {
    setEspSettings({...hardwareManager.GetSettings()});
    deviceStorageManager.NewSettingsEvent(hardwareManager.GetSettings());

  };

  const handleBTDisconnected = (event) => {
    setEspSettings(hardwareManager.GetSettings());
  };


  const handleReconnect = (event) => {
    setReconnecting(true);
  };

  

  useEffect(() => {
    hardwareManager.EventHandler.addEventListener("SettingsUpdated", handleSettingsUpdated);
    hardwareManager.EventHandler.addEventListener("BTDisconnected", handleBTDisconnected);
    hardwareManager.EventHandler.addEventListener("TryingReconnect", handleReconnect);
    hardwareManager.EventHandler.addEventListener("ReconnectFailed", reconnectFailed);


    return () => {
      hardwareManager.EventHandler.removeEventListener('SettingsUpdated',handleSettingsUpdated);
      hardwareManager.EventHandler.removeEventListener('BTDisconnected',handleBTDisconnected);
      hardwareManager.EventHandler.removeEventListener("TryingReconnect", handleReconnect);
      hardwareManager.EventHandler.removeEventListener("ReconnectFailed", reconnectFailed);

    };
  }, []);


  return (

    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <div className="App">

      <ResponsiveAppBar  />
      <ReconnectingModal showModel={reconnecting} />
      <ConnectionFailedComponant toggler={connectionFailRef}  />

      

      <Box sx={{paddingTop:2, paddingBottom:10,  height: "100%"}}>

        <Routes basename='/'>
          <Route path="/" element={<VariantButtonGroup  /> } />
          <Route path="/colorpicker" element={<ColorPickerPage    /> } />
          <Route path="/animation-maker" element={<AnimationMakerPage  /> } />
          <Route path="/settings" element={<SettingsPage  /> } />

        </Routes>
      </Box>

      <SimpleBottomNavigation />
 
    </div>
    </ThemeProvider> 
  );
}

export default App;
