import Reac, {useEffect, useState,useRef  } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { HexColorPicker } from "react-colorful";

import ConnectToBluetoothHolder from './ConnectToBluetoothHolder';

import MultiLedColourPicker from './MultiLedColourPicker';

import { hardwareManager } from './Helpers/HardwareController';

export default function ColorPickerPage() {

    const reciveHandler = (data)=>{
        let options = data.detail.split(":");
        let leds = [];
        if(options[0] == 'Q' && options[1] != "NOTSET")
        {
            let allLeds = options[1].substring(1).split('#'); // remove first hash so we dont get a blank start
            if(allLeds.length == hardwareManager.GetSettings().NumberLEDS)
            {
                allLeds.forEach((ledCol)=>{
                    leds.push("#"+ledCol);
                });
            }
            else
            {
                leds = ledarray();
            }
        }
        else
        {
            leds = ledarray();
        }
        setColorArray(leds);
        setChildKey(Date.now());

    };



    const ledarray = ()=>
    {
        let leds = [];
        if(hardwareManager.GetSettings().NumberLEDS > 0)
        {
            for(let i =0; i < hardwareManager.GetSettings().NumberLEDS; i++)
            {
                leds[i]="#ffffff";
            }
        }
        return leds;
    };



    const initialized = useRef(false)



    useEffect(()=>
    { 

        if(hardwareManager.CheckIsConnected())
        {
            hardwareManager.EventHandler.addEventListener("BTDataRecived", reciveHandler);
            if(!initialized.current)
            {
                hardwareManager.RequestActiveColourPage();
                initialized.current = true;
            }
        return () => {
            hardwareManager.EventHandler.removeEventListener('BTDataRecived',reciveHandler);
          };
        }
    },[]);
     

    const [color, setColor] = useState(["#b32aa9"]);
    const [colorarray, setColorArray] = useState([]);

    const [allLedColour, setAllLedColour] = useState("#b32aa9");

    const [sending,setSending] = useState(false);

    const [lastUpdate,setLastUpdate] = useState([]);

    const [chldKey,setChildKey] = useState(Date.now());


    const updateAllColours = (val) => {
        setColor([val[0]]);
        setAllColourFunc(val); 
        setChildKey(Date.now());
    };

    
    const setAllColourFunc = (cc) =>
    {
        let arr = [];
        for(let i =0; i < hardwareManager.GetSettings().NumberLEDS; i++)
        {
            arr[i]=cc[0];

        }
        setColorArray(arr);
    } 


    useEffect(()=>
    {
        if(colorarray.length < 1)
        {
            return;
        }

        if(colorarray.join() != lastUpdate.join())
        {
            sendLedUpdate(colorarray);
        }
    },[colorarray,sending]);
    

    async function sendLedUpdate(array)
    {
        if(sending)
        {
            return;
        }

        setSending(true);

        await hardwareManager.SendLiveColourChange(array);
        setLastUpdate(array); 

        setSending(false);
    }




    function ledColoursChangedEvent(e)
    {

        setColorArray([...e]) ;
       // reportFrameData();

    }
  return (

    <Box>
          <h1>Live Colours</h1>
            {!hardwareManager.CheckIsConnected() ?
                <ConnectToBluetoothHolder /> 
            :
                <section className="resposive example">             
                    <MultiLedColourPicker frameCurrentSettings={color} onStateChange={(e)=>{updateAllColours(e);}} numberOfLEDS={1}/> 
                    <div className="value" style={{ borderLeftColor: color }}>
                        Current color is {color}
                    </div>
                    <div className="pickAColourSingle" style={{display:"flex", flexWrap:"wrap", alignItems:"center", justifyContent:"center", paddingBottom:50}}>
                        <MultiLedColourPicker key={chldKey} frameCurrentSettings={colorarray} onStateChange={(e)=>{ledColoursChangedEvent(e)}} numberOfLEDS={hardwareManager.GetSettings().NumberLEDS}/> 
                    </div>
                </section>
            }
    </Box>
  );
}