import { useRef,useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AnimationFrameSingle  from './AnimationFrameSingle';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { hardwareManager } from './Helpers/HardwareController';
import { animationManager } from './Helpers/Animations/AnimationManager.ts';
import { deviceStorageManager } from './Helpers/DevicesManager.ts';
import AnimationsStorageTemplate from './Helpers/Animations/AnimationsStorageTemplate.ts';
import AnimationSimulator from './Helpers/Animations/AnimationSimulator.ts';
import Modal from '@mui/material/Modal';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import BlurOnIcon from '@mui/icons-material/BlurOn';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };


const getAnimationSlot = (animationID) =>
{
  let res =animationManager.GetAnimationStateFromLocalCache(animationID);
  if(res === null)
  {
    return  animationManager.GetNewAnimationTemplate(animationID,deviceStorageManager.ActiveDevice.NumberLEDS)

  }
  else
  {
    return res;
  }

};
export default function AnimationManagerTab({animationID,proigateChange}) {



    const [value, setValue] = useState(0);
    const [tabData, setTabData] = useState(getAnimationSlot(animationID));
    const [enabledStatusOfAnimations, setEnabledStatusOfAnimations] = useState(0);


    const initialized = useRef(false)

    const reciveHandler = (data)=>{
      let options = data.detail.split(":");
      if(options[0] == 'F')
      {
        if(options[2]=="0")
        {
          setEnabledStatusOfAnimations(0);
        }
        else if(options[2]=="1")
        {

          setEnabledStatusOfAnimations(1);

        }

      }



    };


    useEffect(()=>
    { 
        if(hardwareManager.CheckIsConnected())
        {
            hardwareManager.EventHandler.addEventListener("BTDataRecived", reciveHandler);
            if(!initialized.current)
            {
                hardwareManager.RequrstAnimationEnabledStatus(animationID);
                initialized.current = true;
            }
        return () => {
            hardwareManager.EventHandler.removeEventListener('BTDataRecived',reciveHandler);
          };
        }
    },[]);


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function handleAddNewClick(tabID)
    {


        let animationBlank = animationManager.GetBlankAnimationFrame(deviceStorageManager.ActiveDevice.NumberLEDS);
        let sets = [...tabData.AnimationFrames];   
        sets.splice(tabID+1, 0, animationBlank);
        tabData.AnimationFrames = sets;
        setTabData(tabData);
        setValue(tabID+1);
        animationManager.UpdateState(animationID,tabData );

    }
    
    function deleteClickHandler(tabID)
    {
        let sets = [...tabData.AnimationFrames];   
        sets.splice(tabID, 1);
        tabData.AnimationFrames = sets;
        setTabData(tabData);
        setValue(tabID-1);
        animationManager.UpdateState(animationID,tabData );
 
    }





    const getTabHeaders = (data) =>
    {
        let content = [];
        let i = 0;
        data.AnimationFrames.forEach(element => {
            content.push(<Tab label={"Frame " + i} />);
            i++;
        })
        return (content)
    };

    function frameChangeEvent(eventData)
    {

        let sets = [...tabData.AnimationFrames];   
        sets[eventData.tab].LEDSHex=eventData.Data;
        sets[eventData.tab].TranSpeed=eventData.tranSpeed;
        sets[eventData.tab].TransitionID=eventData.transitionID;
        tabData.AnimationFrames = sets;

        animationManager.UpdateState(animationID,tabData );
        console.log(tabData);
        setTabData(tabData);

    }
    
    const getTabPages = (data) =>
    {

        let content = [];
        let i = 0;
        data.AnimationFrames.forEach(element => {
        
            content.push(
                <CustomTabPanel value={value} index={i}> 
                    <AnimationFrameSingle tabData={tabData.AnimationFrames[i]} tabNumber={i} frameChangeEvent={frameChangeEvent}  addClickHandler={handleAddNewClick} deleteClickHandler={deleteClickHandler}/> 
                </CustomTabPanel>
            
            );
           i++;
        })
        return (content)


    };


    const testAnimation = () =>
    {
        let as = new AnimationSimulator();
        as.LoadAnimation(tabData);
        as.StartAnimation();
        return as.GetCanvas();

    };

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const [open, setOpen] = useState(false);
    const [renameActive, setRenameActive] = useState(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const divRef = useRef(null);
    useEffect(() => {
      if(open)
      {divRef.current.innerHTML = '';
        divRef.current.appendChild(testAnimation());
      }
    }, [open]);


    const valueRef = useRef('') //creating a refernce for TextField Component



    function nameSaveHandler()
    {
      setRenameActive(false);
      tabData.AnimationName = valueRef.current.value;
      animationManager.UpdateState(animationID,tabData );
      proigateChange();
    }


    return (
        <Box>


        <Grid container spacing={4}   justifyContent="center" marginTop="20px">
          <Grid item xs={10} md={5} elevation={2}>
            <Card  sx={{minHeight:1}}  elevation={2} >
              <Typography p="10px" backgroundColor="#f9f9f9" variant="h6" component="h6">
                Local Options
              </Typography>
                <Divider  />
                <Box  p="10px">
                {!renameActive ? 
             

                (<div><Button sx={{marginBottom:1}} onClick={()=>setRenameActive(true)} variant="outlined"  size="large">Rename</Button>  <Button sx={{marginBottom:1}}  variant="outlined"  size="large">Duplicate</Button> <Button  sx={{marginBottom:1}}  variant="outlined" onClick={handleOpen}   size="large">Test</Button></div>)
                :
                (<div><TextField id="outlined-basic" label="Outlined" variant="standard" inputRef={valueRef} inputProps={{ maxLength: 20 }} /> <Button sx={{marginBottom:1, marginTop:1}} onClick={()=>nameSaveHandler()} variant="outlined"  size="large">Save</Button>   <Button sx={{marginBottom:1, marginTop:1}}  onClick={()=>setRenameActive(false)} variant="outlined"  size="large">Cancel</Button>               </div>)
                }
                 
                  </Box>
                </Card>
            </Grid> 
            <Grid item xs={10} md={5}>
              <Card sx={{minHeight:1}}  elevation={2} >
                <Typography p="10px" variant="h6" component="h6" backgroundColor="#f9f9f9">
                  Sync Options
                </Typography>
                <Divider />
                <Box  p="10px">
                  {
                  !hardwareManager.CheckIsConnected() ? 
                    <Alert   variant="outlined" sx={{maxWidth:275, margin:'auto', paddingTop:0.25, paddingBottom:0.25,  textAlign: "left"}}  severity="info">
        
                      You are working in offline mode
                    </Alert>
                  : 
                    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                      <Button startIcon={<FileUploadIcon />}  size="large"   onClick={()=>hardwareManager.SendAnimationUpdate(animationID,tabData)}>Send to device</Button> 
                      <Button  startIcon={<FileDownloadIcon />}  size="large" >Load from device</Button>

                      ({enabledStatusOfAnimations === 0 ?
                      <Button   onClick={()=>hardwareManager.SendAnimationEnabledStatusUpdate(animationID,1)}   color="error"  startIcon={<BlurOnIcon />}  size="large" >Enable</Button>
                       :
                       <Button    onClick={()=>hardwareManager.SendAnimationEnabledStatusUpdate(animationID,0)}  color="error"  startIcon={<BlurOnIcon />}  size="large" >Disable</Button>

                      })
                    </ButtonGroup>
                  }
                </Box>
              </Card>
            </Grid> 
          </Grid>

          <Box sx={{ marginTop:"20px"}}  ref={divRef} ></Box>

        <Box  sx={{ marginTop:"20px"}}  display="flex" justifyContent="center" width="100%">
          <Tabs
            className='frameSelectionTabs'
            scrollButtons="auto"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            allowScrollButtonsMobile
          >
            {getTabHeaders(tabData)}
            </Tabs>
            </Box>
            <Box>
               {getTabPages(tabData)}
             </Box>       
        </Box>
            )



};