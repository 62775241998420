
import * as React from 'react';
import Button from '@mui/material/Button';
import Reac, { useState, useEffect, useRef } from 'react';
import { hardwareManager } from './Helpers/HardwareController';

export default function SettingsPage({terminal,ledsettings}) {


    const [amount,setAmount] = useState(0);


    const callbacker = (val)=>
    {
        setAmount(val);


    }
    return (
         <div>

            <h1>Settings Page</h1>
            
            {
            hardwareManager.CheckIsConnected() ?   
            (
                <div>
                <Button onClick={()=>terminal.sendUpdate("https://borgling.github.io/webmanagertest/ddttr_copy_20230816133501.ino.bin",callbacker) }>Send</Button>
                <div>{amount}</div>
                </div>
            )
            :        
            (
                <div>Not Connected....</div>
            )
            }
        </div>
        );

}