import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ConnectToBluetoothHolder from './ConnectToBluetoothHolder';
import {hardwareManager} from './Helpers/HardwareController'

export default function VariantButtonGroup() {


  const modes = hardwareManager.GetSettings().Modes;

  const listItems = modes.map(product =>
    <Grid item xs={6}>
   
    <Button  variant="contained"  sx={{fontSize: 12,  width: 1}}
      onClick={()=>{ hardwareManager.SendModeChange(product.id)}}
    >
      {product.name}
    </Button>
   
    </Grid>
  );


  return (

<Box>
<h1>Mode Select</h1>

    {!hardwareManager.CheckIsConnected() ?

    <ConnectToBluetoothHolder /> :
    <Box justifyContent="center">
      <Grid sx={{padding:5}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {listItems}
      </Grid>
    </Box>

  }
</Box>

  );
}