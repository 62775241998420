import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AnimationManagerTab from './AnimationManagerTab'
import { Button } from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BlurOnIcon from '@mui/icons-material/BlurOn';

import {hardwareManager} from './Helpers/HardwareController'
import {deviceStorageManager} from './Helpers/DevicesManager.ts'
import { animationManager } from './Helpers/Animations/AnimationManager.ts';




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (

        
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };







export default function AnimationMakerPage() {



  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);

  };


  const [changeHelper, setrChangeHelper] = React.useState(0);


  const propigateChangeHelper = ()=> {

    let changeHelper2 = changeHelper + 1;
    setrChangeHelper(changeHelper2);

};

  const getTabPages = (data) =>
  {

      let content = [];
      let i = 0;
      data.forEach(element => {
      
          content.push(
              <CustomTabPanel value={value} index={i}> 
                  <AnimationManagerTab proigateChange={propigateChangeHelper}  animationID={i} /> 
              </CustomTabPanel>
          
          );
         i++;
      })
      return (content)


  };

  const getTabHeaders = (data) =>
  {
      let content = [];
      let i = 0;
      //content.push(<Tab label={"New Animation"} icon={<AddCircleOutlineIcon />}  />);

      data.forEach(element => {
          content.push(<Tab label={element.AnimationName + " (" + (i+1)  +  ")"} icon={<BlurOnIcon />}/>);
          i++;
      })
      return (content)
  };




    return (



      <Box>

      {
        deviceStorageManager.GetDevices() === null ? 
        (<Box>No Devices. Connect to a device first time to allow offline animation editing</Box>)
        :
      
        (
          <Box>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={10} justifyContent="center"   >
            <Paper elevation={3} sx={{backgroundColor:'#fafafa'}}  >
              <Box  display="flex" justifyContent="center" width="100%">
              <Tabs
            className='frameSelectionTabs'
            scrollButtons="auto"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            allowScrollButtonsMobile
          >
                {getTabHeaders(deviceStorageManager.ActiveDevice.AnimationStorage)}
                </Tabs>
              </Box>
            </Paper>
          </Grid>
        </Grid>
         {getTabPages(deviceStorageManager.ActiveDevice.AnimationStorage)}
        </Box>
      )
        }
      </Box>
    );
      


};