import AnimationsStorageTemplate from "./Animations/AnimationsStorageTemplate.ts";
export default class DeviceStorage
{

    DeviceID:string;
    NumberLEDS:number;
    AnimationStorage:AnimationsStorageTemplate;

    constructor()
    {
        this.DeviceID = "";
        this.NumberLEDS = -1
        this.AnimationStorage = [];
    }




}