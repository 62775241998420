
import AnimationFrameTemplate from "./AnimationFrameTemplate.ts";

export default class AnimationTemplate
{

    AnimationID : number;
    AnimationFrames : Array<AnimationFrameTemplate>;
    SavedTimestamp : number;
    UploadedTimestap : number;
    AnimationName : String;
    
    constructor()
    {
        this.AnimationID = -1;
        this.AnimationFrames = [];
        this.SavedTimestamp = -1;
        this.UploadedTimestap = -1;
        this.AnimationName = "";
    
    }


}