import AnimationFrameSingle from "../../AnimationFrameSingle.js";
import AnimationFrameTemplate from "./AnimationFrameTemplate.ts";
import AnimationTemplate from "./AnimationTemplate.ts";
import AnimationStorageTemplate from "./AnimationsStorageTemplate.ts";
import { deviceStorageManager } from "../DevicesManager.ts";
import { error } from "console";




export class AnimationManager
{

    EventHandler:EventTarget
    localAnimationsState: AnimationStorageTemplate | null;

    constructor()
    {
        this.localAnimationsState = null;
        this.EventHandler = new EventTarget();
    }


    GetFirstAnimationTemplates()
    {
        let animations:Array<AnimationTemplate> = [];

        if(deviceStorageManager.ActiveDevice == null)
        {
            throw Error("Active Device Null");

        }

        for(let i:number = 0; i < 15; i ++)
        {
            animations.push(this.GetNewAnimationTemplate(i,deviceStorageManager.ActiveDevice.NumberLEDS));
        }
        return animations;
        
    }


// not used
    AddNewAnimation()
    {
        deviceStorageManager.ActiveDevice?.AnimationStorage.push(this.GetNewAnimationTemplate(-1,deviceStorageManager.ActiveDevice.NumberLEDS));
        const customEvent = new CustomEvent("AnimationChanged", {"bubbles":true, "cancelable":false});
        this.EventHandler.dispatchEvent(customEvent);      
    }


    GetAnimationCount()
    {
       return deviceStorageManager.ActiveDevice?.AnimationStorage.length;

    }

    DeleteAnimation()
    {


    }

    DuplicateAnimation()
    {


    }

    GetAnimationStateFromLocalCache(animationID)
    {

        return this.GetAnimationState(animationID);
    }


    UpdateAnimationName(animationID:number,newName:string)
    {
        
        if(newName.length > 10)
        {
            throw new Error('Animation Name Too Long');
        }
        if(deviceStorageManager.ActiveDevice !== null)
        {
            deviceStorageManager.ActiveDevice.AnimationStorage[animationID].AnimationName = newName;
        }
    }


    UpdateState(animationID,animationData)
    {

        if(deviceStorageManager.ActiveDevice !== null)
        {
            console.log(deviceStorageManager.ActiveDevice);
            deviceStorageManager.ActiveDevice.AnimationStorage[animationID] = animationData;


         
            const customEvent = new CustomEvent("AnimationChanged", {"bubbles":true, "cancelable":false,"detail":animationData});
            this.EventHandler.dispatchEvent(customEvent);
        }
    }


    GetBlankAnimationFrame(ledNum:number)
    {

        var animationframe = new AnimationFrameTemplate();
        for(let i =0; i < ledNum; i++)
        {
            animationframe.LEDSHex.push("#ffffff");

        }
        return animationframe;

    }


    GetNewAnimationTemplate(animationid:number, ledNum:number) 
    {

            var animation = new AnimationTemplate();
            animation.AnimationID=animationid;
            animation.AnimationFrames = [this.GetBlankAnimationFrame(ledNum)];
            animation.AnimationName = "Animation " + (animationid+1);
            return animation;
    }

    GetAnimationState(animationID:number)
    {
        
        if(deviceStorageManager.ActiveDevice !== null && deviceStorageManager.ActiveDevice.AnimationStorage[animationID] !== undefined)
        {
            return deviceStorageManager.ActiveDevice.AnimationStorage[animationID] 

        }
        else
        {
            return null;

        }

    }



}


export let animationManager = new AnimationManager();

