
import DeviceSettings from "./DeviceSettings.ts";
import DeviceStorage from "./DeviceStorage.ts";
import { animationManager } from "./Animations/AnimationManager.ts";


interface DevicesList {
    [key: string]: DeviceStorage;
 }


class DevicesManager
{

    Devices:DevicesList
    LocalStorageKey:string;
    ActiveDevice:DeviceStorage | null;

    constructor()
    {
        this.Devices = {};
        this.LocalStorageKey = "DEVICE_STORAGE"
        this.ActiveDevice = null;
    }

    AddNewDevice(deviceSettings : DeviceSettings)
    {
        let deviceStroage = new DeviceStorage();
        deviceStroage.NumberLEDS = deviceSettings.NumberLEDS;
        deviceStroage.DeviceID = deviceSettings.DeviceID;
        this.Devices[ deviceStroage.DeviceID] = deviceStroage;

    }


    

    DeviceIDExists(deviceID:string)
    {
        return (deviceID in this.Devices);

    }

    GetDevice(deviceID:string)
    {
        if(this.DeviceIDExists(deviceID))
        {
            return this.Devices[deviceID];
        }
        return null;

    }

    LoadDevicesFromStorage()
    {
        let item = localStorage.getItem(this.LocalStorageKey);
        if(item !== null)
        {
           // Object.assign(this.Devices,item);
            this.Devices = JSON.parse(item) as DevicesList;
            var key = Object.keys(this.Devices)[0];;
            this.ActiveDevice = this.Devices[key];
            animationManager.EventHandler.addEventListener("AnimationChanged", this.SaveLocal.bind(this));

        }


    }

    SaveLocal()
    {
        if(Object.keys(this.Devices).length > 0 )
        {
            localStorage.setItem(this.LocalStorageKey,JSON.stringify(this.Devices));
        }
    }

    GetDevices()
    {
        if(Object.keys(this.Devices).length < 1)
        {
            return null;

        }
        return this.Devices 

    }

    NewSettingsEvent(deviceSettings : DeviceSettings)
    {

        if(this.GetDevice(deviceSettings.DeviceID) !== null)
        {
            this.ActiveDevice = this.GetDevice(deviceSettings.DeviceID);

        }
        else
        {

            this.AddNewDevice(deviceSettings);
            this.ActiveDevice = this.GetDevice(deviceSettings.DeviceID);
            if(this.ActiveDevice  == null)
            {
                throw Error("Unexpected Null");
            }
            this.ActiveDevice.AnimationStorage = animationManager.GetFirstAnimationTemplates();
            this.SaveLocal();
        }
        
    }    
}


export  let deviceStorageManager = new DevicesManager();

