import * as React from 'react';
import { Button } from "@mui/material";
import { HexColorPicker } from "react-colorful";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MultiLedColourPicker from './MultiLedColourPicker';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

var interpolate = function (value, s1, s2, t1, t2, slope) {
    //Default to linear interpolation
    slope = slope || 0.5;

    //If the value is out of the source range, floor to min/max target values
    if(value < Math.min(s1, s2)) {
        return Math.min(s1, s2) === s1 ? t1 : t2;
    }

    if(value > Math.max(s1, s2)) {
        return Math.max(s1, s2) === s1 ? t1 : t2;
    }

    //Reverse the value, to make it correspond to the target range (this is a side-effect of the bezier calculation)
    value = s2-value;

    var C1 = {x: s1, y:t1}; //Start of bezier curve
    var C3 = {x: s2, y:t2}; //End of bezier curve
    var C2 = {              //Control point
        x: C3.x,
        y: C1.y + Math.abs(slope) * (C3.y - C1.y)
    };

    //Find out how far the value is on the curve
    var percent = value / (C3.x-C1.x);

    return C1.y*b1(percent) + C2.y*b2(percent) + C3.y*b3(percent);

    function b1(t) { return t*t }
    function b2(t) { return 2*t*(1 - t)  }
    function b3(t) { return (1 - t)*(1 - t) }
};



function valueLabelFormat(value) {
  

    let scaledValue = value / 1000;

    let string = scaledValue.toFixed(3).toString().split('.');

    let returner = string[0] != "0" ? string[0] + " Sec" : "";
    returner += " " + parseInt(string[1]) + "ms";



    return returner;

  }


  function calculateValue(value) {

    let val = Math.round(interpolate(value,0,10000,0,60000,0.001));

    return val;
  }

export default function AnimationFrameSingle({addClickHandler, deleteClickHandler, tabNumber, frameChangeEvent,tabData}) {

    let ledData = tabData.LEDSHex; 
    let tranSpeed = tabData.TranSpeed; 
    let transitionID = tabData.TransitionID; 


    function reportFrameData()
    {
        frameChangeEvent({tab:tabNumber, Data:ledData,tranSpeed:tranSpeed,transitionID:transitionID});
    }


    function ledColoursChangedEvent(e)
    {

        ledData = e;
        reportFrameData();

    }

    const [alignment, setAlignment] = React.useState(tabData.TransitionID + "");

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
          setAlignment(newAlignment);
          transitionID=parseInt(newAlignment);
          reportFrameData();
        }
      };
    
    const buttons = [
        <ToggleButton value="0">None</ToggleButton >,
        <ToggleButton value="1">Fade</ToggleButton >,
        <ToggleButton value="2">1 By 1</ToggleButton >,
      ];


      const [speedValue, setSpeedValue] = React.useState(tabData.TranSpeed);

      const handleSpeedChange = (event, newValue) => {
        setSpeedValue(newValue);
        tranSpeed=newValue;
        reportFrameData();
      };
    

return (

    <Box>

    { /*<MultiLedColourPicker numberOfLEDS={ledsettings.numberOfLEDS} /> */}
    <MultiLedColourPicker frameCurrentSettings={tabData.LEDSHex} onStateChange={(e)=>{ledColoursChangedEvent(e)}} numberOfLEDS={tabData.LEDSHex.length}/> 

    <Typography gutterBottom>
        <h3>Transition</h3>
      </Typography>
    <ToggleButtonGroup size="large" aria-label="Large sizes"  onChange={handleAlignment} value={alignment} exclusive>
{buttons}
</ToggleButtonGroup>

<Box sx={{maxWidth:300, margin:'auto'}}>
<Typography  gutterBottom>
    <h3>Animation Time</h3>
</Typography>


<Slider   
  max={10000}
  min={0} scale={calculateValue}
  valueLabelFormat={valueLabelFormat}
  valueLabelDisplay="on" onChange={handleSpeedChange} aria-labelledby="input-slider" value={speedValue} />
    
</Box>

<Box sx={{maxWidth:300, margin:'auto'}}>
<Typography  gutterBottom>
    <h3>Options</h3>
</Typography>

<Stack   spacing={2}>
    <Button onClick={()=>{addClickHandler(tabNumber)}} variant="outlined">Add Next</Button>
    {tabNumber > 0 ? (
    <Button onClick={()=>{deleteClickHandler(tabNumber)}} variant="outlined"  color="error">Delete</Button>
    ) : (<></>)}

</Stack>
</Box>
</Box>
);



};