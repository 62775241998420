export default class DeviceSettings
{
    DeviceID:string;
    Version:string;
    NumberLEDS:number;
    Brightness:number;
    CurrentAnimationMode:number;
    NumUserAnimations:number;
    Modes:Array<string>;

    constructor()
    {
        this.DeviceID = "";
        this.Version = "";
        this.NumberLEDS = -1;
        this.Brightness = -1;
        this.CurrentAnimationMode = 0;
        this.NumUserAnimations = 0;
        this.Modes = [];
    }
}