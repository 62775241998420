import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import Grid from '@mui/material/Grid';
import {hardwareManager} from './Helpers/HardwareController'

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function ConnectToBluetoothHolder() {
  return (
    <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{position:'fixed', height:'80%'}}
    >
    <Card sx={{ minWidth: 275, maxWidth:300 }}>
      <CardContent>
      <Typography variant="h5" component="div">
         Not connected....
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          This functionality requres you to be connected to bluetooth
        </Typography>


      </CardContent>
      <CardActions>
        <Button onClick={async () => {await hardwareManager.Connect() ? await hardwareManager.LoadSettings() : alert("Connection Failed")}} startIcon={<BluetoothConnectedIcon/>} size="large">Connect</Button>
      </CardActions>
    </Card>
    </Grid>
  );
}