import { inherits } from "util";
import AnimationTemplate from "./AnimationTemplate"


class RGB
{

    R:number;
    G:number;
    B:number;

    constructor(hex:string)
    {
        let stripped = hex.replace('#','');

        let rHex = stripped.charAt(0) + stripped.charAt(1);
        let gHex = stripped.charAt(2) + stripped.charAt(3);
        let bHex = stripped.charAt(4) + stripped.charAt(5);


        this.R= parseInt(rHex, 16);
        this.G= parseInt(gHex, 16);
        this.B= parseInt(bHex, 16);


        

    }

    toHex()
    {
        return  '#' + this.componentToHex(this.R) + this.componentToHex(this.G) + this.componentToHex(this.B) ;


    }


    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }
}

export default class AnimationSimulator
{
    LoadedAnimation:AnimationTemplate | null;
    Context:CanvasRenderingContext2D;
    Canvas:HTMLCanvasElement;
    Frame:number;
    NextFrame:number;

    LastFrameTime:number;
    Step:number;
    CurrentColourValues:Array<string>;
    AnimationEnabled:boolean;
    LastLeftOver:number;
    constructor() {
        this.LoadedAnimation = null;
        this.Canvas  = document.createElement('canvas');
        //this.Canvas.width = 600; 
        //this.Canvas.height = 50;
        this.Canvas.style.width = '80%';
        this.Canvas.style.borderRadius = '8px';

        this.Context = this.Canvas.getContext('2d') as CanvasRenderingContext2D;
        this.Frame = 0;
        this.NextFrame = 1;
        this.Step = 0;
        this.LastFrameTime = 0;
        this.CurrentColourValues = [];

        this.LastLeftOver = 0;
        
        this.AnimationEnabled = false;

    }

    _blendArray(inHexArray:Array<string>,targetHexArray:Array<string>, outArray:Array<string>, fraction:number )
    {
        for(let i = 0; i < inHexArray.length; i++)
        {

            outArray[i] = this._blendSingle(inHexArray[i],targetHexArray[i],fraction);

        }        

    }
    
    _blendSingle(existing:string,target:string, fraction:number)
    {

        if( fraction == 0) {
            return existing;
        }
     
        if( fraction == 255) {
            existing = target;
            return existing;
        }  
        
        let exRGB  = new RGB(existing);
        let targetRGB  = new RGB(target);
        
        exRGB.R = this._blend(exRGB.R,targetRGB.R,fraction);
        exRGB.G = this._blend(exRGB.G,targetRGB.G,fraction);
        exRGB.B = this._blend(exRGB.B,targetRGB.B,fraction);

        return exRGB.toHex();
    }

    _blend(a:number,b:number,amountOfB:number)
    {

        let amountOfA:number = 255-amountOfB;
        
        return this._scale(a,amountOfA) + this._scale(b,amountOfB);
    }

    _scale(num:number,scale:number)
    {

        let res = Math.trunc ((num * (scale)) / 256) ;
        return res;
    }


    LoadAnimation(at:AnimationTemplate)
    {
        this.LoadedAnimation = at;

        
    }

    StartAnimation()
    {


        this.AnimationEnabled = true;
        requestAnimationFrame(()=>this.AnimationLoop());


    }

    StopAnimation()
    {

        this.AnimationEnabled = false;

    }

    AnimationLoop()
    {

        this.DoFrame();
        if(this.AnimationEnabled === true)
        {
            requestAnimationFrame(()=>this.AnimationLoop());
        }
    }


    _scaleRange(x:number,in_min:number,in_max:number,out_min:number,out_max:number)
    {
         return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    }

    DoFrame()
    {

        if(this.LoadedAnimation === null)
        {
            throw("ERROR");
            
        }

        if(this.CurrentColourValues.length < 1)
        {

            this.CurrentColourValues = [...this.LoadedAnimation.AnimationFrames[0].LEDSHex ]
            this.LastFrameTime = Date.now();
            this.DrawCanvas(0);

        }


        let oneFrameTime = (this.LoadedAnimation.AnimationFrames[this.Frame].TranSpeed * 1000) / 255;
        let timeSinceLastFrame = Date.now() - this.LastFrameTime;
        this.LastFrameTime =  Date.now();
        
        if(oneFrameTime < 1)
        {
            this.Step =255;

        }
        else
        {
            let steps = ( this._scaleRange(timeSinceLastFrame,0,oneFrameTime,0,255));
            this.Step +=steps

        }

        if(true )
        {


   
            


            if(this.LoadedAnimation.AnimationFrames[this.Frame].TransitionID === 0 )
            {
                if(this.Step > 254)
                {
                    this.Step = 255;
                    this._blendArray(this.LoadedAnimation.AnimationFrames[this.Frame].LEDSHex,this.LoadedAnimation.AnimationFrames[this.NextFrame].LEDSHex,this.CurrentColourValues,this.Step);
                    this.DrawCanvas(this.Step);
                }

            }
            else if(this.LoadedAnimation.AnimationFrames[this.Frame].TransitionID === 1)
            {
                
                this._blendArray(this.LoadedAnimation.AnimationFrames[this.Frame].LEDSHex,this.LoadedAnimation.AnimationFrames[this.NextFrame].LEDSHex,this.CurrentColourValues,      Math.trunc(this.Step) );
                this.DrawCanvas(this.Step);

            }
            else if(this.LoadedAnimation.AnimationFrames[this.Frame].TransitionID === 2)
            {
                let numLeds =  (this.LoadedAnimation.AnimationFrames[this.NextFrame].LEDSHex.length) / ( (255/this.Step));
                let uptoLed = Math.floor(numLeds);
                for(let i = 0; i < Math.floor(numLeds); i++)
                {
         
                    this.CurrentColourValues[i]=this.LoadedAnimation.AnimationFrames[this.NextFrame].LEDSHex[i];
                }   
                
                
                  //let exist = this.CurrentColourValues[uptoLed-1];
                  //this.CurrentColourValues[uptoLed-1]="#ffffff";
             

                this.DrawCanvas(numLeds);
                //this.CurrentColourValues[uptoLed-1]=exist;


            }

            if(this.Step >=255)
            {
                this.Step = 0;
                this.Frame++;
               this.NextFrame++;
                if(this.NextFrame > this.LoadedAnimation.AnimationFrames.length - 1 )
                {
                    this.NextFrame = 0;
                }

                if(this.Frame >  this.LoadedAnimation.AnimationFrames.length - 1  )
                {

                    this.Frame = 0;
                }
                    
            }
        }

    }

    DrawCanvas(frac:number)
    {

        let width = this.Canvas.offsetWidth;
        let numLeds = this.CurrentColourValues.length;
        let squareSize = 20;
        let leftPadding = 10;
        
        let size = squareSize+leftPadding;
        let totalSize = width/((numLeds*size)+10)

        this.Canvas.width = this.Canvas.offsetWidth;
        let rows = Math.ceil(1/totalSize) ;
        this.Canvas.height = rows * 50;

        let perRow = Math.floor(numLeds/rows);


        this.Context.fillStyle = "#000000";

        this.Context.fillRect(0,0,this.Canvas.width,this.Canvas.height);

        let offset =  ((this.Canvas.offsetWidth-(perRow * size)) / 2);

        let count = 0;
        let row = 0;
        let col = 0;


        this.CurrentColourValues.forEach((e)=>
        {

            this.Context.fillStyle = e;
            this.Context.shadowColor = e;
            this.Context.shadowBlur = 10;


            this.Context.fillRect((col*size)+offset, (row*50) + 13,20,20);
            col++;
            count++;
            if(count > perRow)
            {
                row++;
                col=0;
                count=0;
            }
        }
        );

        this.Context.font = "30px Comic Sans MS";
        this.Context.fillStyle = "red";
        this.Context.textAlign = "center";
    
       // this.Context.fillText(this.Canvas.offsetWidth.toString() , this.Context.canvas.width/2, this.Context.canvas.height/2);


    }


    GetCanvas()
    {
        return this.Canvas;

    }

    


}