import BluetoothTerminal from './BluetoothTerminal';
import AnimationFrameTemplate from './Animations/AnimationFrameTemplate.ts'

import { animationManager } from './Animations/AnimationManager.ts';
import DeviceSettings from './DeviceSettings.ts';



class HardwareController 
{

    constructor(bt)
    {
        this.currentSettings = new DeviceSettings();
        this.bluetoothTerminal = bt;
        this.bluetoothTerminal.receive = this._handleBTRecived.bind(this); // new way to bind ?
        this.bluetoothTerminal.manualDisconnect = this._disconnected.bind(this);
        this.bluetoothTerminal.tryingReconnect = this._tryingReconnect.bind(this);
        this.bluetoothTerminal.reconnectFailed = this._reconnectFailed.bind(this);

        this.EventHandler = new EventTarget();
        this.SendingBlocked = false;

    }

    _handleBTRecived(data)
    {
   
        let option = data.charAt(0);
        console.log("Got Data: " + data);
        if(option == "S")
        {
            this._btSettingsRecived(data);
        }
        else
        {
            const customEvent = new CustomEvent("BTDataRecived", {"bubbles":true, "cancelable":false,"detail":data});
            this.EventHandler.dispatchEvent(customEvent);

        }
    }

    _btSettingsRecived(data)
    {
        let options = data.split(":");


        this.currentSettings.DeviceID = options[1];
        this.currentSettings.NumberLEDS = parseInt(options[2]);
        this.currentSettings.Brightness = parseInt(options[3]);
        this.currentSettings.CurrentAnimationMode = parseInt(options[4]);
        this.currentSettings.Modes = [];
        let id = 0;
        options[5].split(',').forEach((mode) =>{
            this.currentSettings.Modes.push({id:id,name:mode});
            id++;
        });
        this.currentSettings.NumUserAnimations = parseInt(options[6])
        const customEvent = new Event("SettingsUpdated", {"bubbles":true, "cancelable":false});
        this.EventHandler.dispatchEvent(customEvent);

    }

    GetSettings()
    {
        return this.currentSettings;
    }

    async Connect()
    {

        try
        {
         await this.bluetoothTerminal.connect();  
        }
        catch(error)
        {
            return false;
        }
        return true;
    }

    Disconnect()
    {

        this.bluetoothTerminal.disconnect();

    }

    async _disconnected()
    {
        this.currentSettings = new DeviceSettings();
        const customEvent = new Event("BTDisconnected", {"bubbles":true, "cancelable":false});
        this.EventHandler.dispatchEvent(customEvent);
    }

    async LoadSettings()
    {

        await this.bluetoothTerminal.send("S");
    }


    _tryingReconnect()
    {
        const customEvent = new Event("TryingReconnect", {"bubbles":true, "cancelable":false});
        this.EventHandler.dispatchEvent(customEvent);

    }

    _reconnectFailed()
    {

        const customEvent = new Event("ReconnectFailed", {"bubbles":true, "cancelable":false});
        this.EventHandler.dispatchEvent(customEvent);
    }

    CheckIsConnected()
    {
        return this.bluetoothTerminal.getDeviceName() !== ''
    }

    RequestActiveColourPage()
    {
        this.bluetoothTerminal.send("Q");


    }

    async SendAnimationUpdate(animationSlot,animationData)
    {

        let animationdata = animationManager.GetAnimationState(animationSlot);

        // send header first H is header flag
        let header = "H";
        let safeName = animationdata.AnimationName.replace(":","").substring(0,20);
        let frameCount = animationdata.AnimationFrames.length;
        let headerString = header + "" + animationSlot + ":" + safeName + ":" + frameCount;

        await this.bluetoothTerminal.send(headerString );

        // to do, we should add a sendWaitForResponce thing.

        let i = 0;
        const dlength = animationData.AnimationFrames.length;

        for await (const frame of animationData.AnimationFrames)
        {
            const sendSTring = "A" + animationSlot + ":" +  i + ":" + dlength + ":" + frame.TransitionID + ":" + frame.TranSpeed +  ":" + frame.LEDSHex.join("");
            await this.bluetoothTerminal.send(sendSTring );
            i++;
        }


    }


    async SendAnimationEnabledStatusUpdate(animationID,animationStausBool)
    {
        let enableString = "E" + animationID + ":" + (animationStausBool ? "1" : "0");
        await this.bluetoothTerminal.send(enableString );
        await  this.LoadSettings(); // reload settings to get updated modes list.. 
    }
    async RequrstAnimationEnabledStatus(animationID)
    {
        let enableString = "F" + animationID;
        await this.bluetoothTerminal.send(enableString );
    }


    GetAnimationFrameData(animationID)
    {
        
        return [new AnimationFrameTemplate()]
    
    }


    async SendLiveColourChange(array)
    {

        let ledstring ="";

        for(let i =0; i < this.GetSettings().NumberLEDS; i++)
        {  
            ledstring += array[i];
        }

        if(ledstring.substring(1).split('#').length == this.GetSettings().NumberLEDS)
        {
            await this.bluetoothTerminal.send("C" + ledstring);
        }

    }

    async SendModeChange(modeid)
    {
       await this.bluetoothTerminal.send("M"+modeid);

    }

}

var serviveUuid = "000b8ae9-c117-4ee3-97e0-29a37d4b58a3";
var characteristicUuid  = "4d4bb4ab-b095-4e27-a3de-6f43973a1de3";
export let hardwareManager = new HardwareController(new BluetoothTerminal(serviveUuid,characteristicUuid,'\n','\n'));