import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  maxWidth:300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ReconnectingModal({showModel}) {


  return (
    <div>

      <Modal
        open={showModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <BluetoothConnectedIcon  fontSize="large" className="redBlackAnimation" />

          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p>Bluetooth has disconnected. </p>

            <p>Check device is powered and in range. </p>   

            <p className='loading'> Trying to reconnect...</p>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}