import AnimationTemplate from "./AnimationTemplate";


export default class AnimationFrameTemplate
{

    TranSpeed : number;
    TransitionID : Number;
    LEDSHex: Array<string>;


    constructor()
    {

        this.TranSpeed = 1000;
        this.TransitionID = 0;
        this.LEDSHex = [];
    }

}