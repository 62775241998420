import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AnimationIcon from '@mui/icons-material/Animation';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SettingsIcon from '@mui/icons-material/Settings';

export default function SimpleBottomNavigation() {
  const pathname = window.location.pathname; // in case user visits the path directly. The BottomNavBar is able to follow suit.
  const [value, setValue] = React.useState(pathname);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:999 }} elevation={3}>

      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
      >
        <BottomNavigationAction component={Link} to="/" label="Mode Select" icon={<FormatListBulletedIcon />} />
        <BottomNavigationAction component={Link} to="/colorpicker"  label="Manual" icon={<ColorLensIcon />} />
        <BottomNavigationAction component={Link} to="/animation-maker"  label="Animation Maker" icon={<AnimationIcon />} />       
        <BottomNavigationAction component={Link}  to="/settings"  label="Settings" icon={<SettingsIcon />} />
      </BottomNavigation>
 
    </Paper>

  );
}